import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Headline from 'components/Theme/Headline';
import { styled } from 'components/Theme/Styles';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Layout from 'components/Layouts/Default';
import ProductListItem from 'components/Product/ProductListItem';

const Hero = styled.div`
  display: flex;
  flex-flow: row;
  position:relative;
  height:50rem;
  width:100%;
  justify-content: space-around;
  align-items: stretch;
  box-sizing:border-box;
  background-color:#f0f0f0;
  padding:10rem 0;
`;

const HeroInnerWrapper = styled.div`
  width:100%;
  flex: 1;
  display:flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  overflow: hidden;
  position:relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  text-align:center;
`;


class ProductList extends Component {

  render() {
    const {
      title,
      products } = this.props.data.shopifyCollection;

    return (
      <Layout>
          {/* <Meta title={title}
                description={description}
                searchEngineFeatures={searchEngineFeatures}
                robotsAdvance={robotsAdvance}
                canonicalUrl={canonicalUrl}
                pageImage={pageImage}
                variables={meta && meta.variables}
                schemaWebpage
                openGraph
                twitter /> */}

          <Header floating={true} inverse="true" />
          <div id="content">
          <Hero>
            <HeroInnerWrapper>
              <Headline size="h2">{ title }</Headline>
            </HeroInnerWrapper>
          </Hero>

          {(products || []).map((product, i) => {
            return <ProductListItem key={`product_${i}`} {...product} />
          })}
          </div>

          <Footer />
      </Layout>
    )
  }
}

export default ProductList;

// language=GraphQL
export const productListQuery = graphql`
  query ProductListQuery($id: String!) {
    shopifyCollection(id: { eq: $id }) {
      title
      handle
      description
      descriptionHtml
      products {
        handle
        title
        descriptionHtml
        description
        variants {
          sku
        }
      }
    }
  }
`;
