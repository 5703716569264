import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Meta from 'components/Meta'
import { styled, media } from 'components/Theme/Styles';
import ThemeBlock, { Section } from 'components/Theme/Block';
import Headline from 'components/Theme/Headline';
import FAQs from 'components/Block/Faq';
import Overview from 'components/Product/Overview';
import Features from 'components/Product/Features';
import Specs from 'components/Product/Specs';
import CompareModels from 'components/Product/CompareModels';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Layout from 'components/Layouts/Default';
import { FilePdf } from 'styled-icons/fa-solid/FilePdf'

const Tabs = styled.div`
  display:flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  border-bottom:0.1rem solid #ddd;
  width:100%;
  background-color:#fff;
  z-index:4;
  top:0;
  padding-left:2rem;
  padding-right:2rem;
  
  ${media.lessThan("tablet")`
    display:none;
  `}
`;

const Tab = styled.a`
  cursor:pointer;
  font-family: ${p => p.theme.fontFamilyTitle};
  font-size:${p => p.theme.h4FontSize}rem;
  color: ${p => p.theme.black};
  font-weight: ${p => p.selected ? '400' : '300'};
  align-self: center;
  text-align: center;
  box-sizing: border-box;
  padding:3rem 2rem;
  border-bottom:${p => p.selected && `0.3rem solid ${p.theme.brandPrimary}`};
  ${p => p.right && `
    justify-self: end;
    margin-left:auto;
  `}
  ${p => p.small && `
    font-size:1.3rem;
    color:#000;
  `}
`;

const Hero = styled.div`
  display: flex;
  flex-flow: row;
  position:relative;
  height: 100%;
  width:100%;
  justify-content: space-around;
  align-items: stretch;
  box-sizing:border-box;
  background-color:#f0f0f0;
  padding:10rem 0;
`;

const HeroInnerWrapper = styled.div`
  width:100%;
  flex: 1;
  display:flex;
  flex-direction: column;
  align-content: center;
  overflow: hidden;
  position:relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  text-align:center;
`;



const FAQHeadline = styled(Headline)`
  width:auto;
  margin:5rem;
  padding:2rem;
  
  text-align:center;
`;


const FAQWrapper = styled.div`
  padding:5rem;
`;

class Product extends Component {
  render() {
    const { site, contentfulProduct, shopifyProduct } = this.props.data;

    const {
      searchEngineFeatures,
      robotsAdvance,
      canonicalUrl,
      pageImage,
      tagline,
      title,
      specs,
      certifications,
      specAssets,
      specSheet,
      features,
      featuresAsset,
      featureBlocks,
      compareModels,
      availableForECommerce,
      faqs,
      meta } = contentfulProduct || {};
  

    const {
      description,
      descriptionHtml,
      id,
      storefrontId
     } = shopifyProduct;

    const images = contentfulProduct && contentfulProduct.images;

    const sku = shopifyProduct.variants[0].sku;
    const price = shopifyProduct.variants[0].price;

    return (
      <Layout>
        <Meta title={title}
              description={description}
              searchEngineFeatures={searchEngineFeatures}
              robotsAdvance={robotsAdvance}
              canonicalUrl={canonicalUrl}
              pageImage={pageImage}
              variables={meta && meta.variables}
              schemaWebpage
              openGraph
              twitter />

        <Header inverse={true} />

        <div id="content">

        <Overview buyButtonId={storefrontId} price={price} availableForECommerce={availableForECommerce} name={title} modelNumber={sku} tagline={tagline} description={descriptionHtml} carouselImages={images} site={site} specSheet={specSheet} />

        { contentfulProduct &&
          <>
            { featureBlocks &&
              <Features features={features} image={featuresAsset} blocks={featureBlocks}/>
            }

            {specs &&
              <Specs specs={specs} images={specAssets} specSheet={specSheet} certifications={certifications} />
            }

            {faqs &&
              <ThemeBlock id="faqs">
                <Section innerWidth="100%" innerPadding="None">
                  <FAQHeadline h2>FAQs</FAQHeadline>
                  <FAQWrapper>
                    <FAQs faqs={faqs}/>
                  </FAQWrapper>
                </Section>
              </ThemeBlock>
            }

            { compareModels &&
              <CompareModels models={compareModels} />
            }
          </>
        }
        </div>

        <Footer />
      </Layout>
    );
  }
}

export default Product;

// language=GraphQL
export const productQuery = graphql`
  query ProductQuery($shopifyId: String!, $variantsBase64: [String]) {
    shopifyProduct(shopifyId: { eq: $shopifyId }) {
      descriptionHtml
      id
      storefrontId
      title
      media {
        preview {
          image {
            gatsbyImageData
          }
        }
      }
      variants {
        price
        shopifyId
        sku
      }
    }

    contentfulProduct(shopify: { in: $variantsBase64 }) {
      shopify
      title
      shortDescription
      tagline
      availableForECommerce
      pageImage {
        gatsbyImageData
      }
      meta {
        ...Meta
      }
      hero {
        ...Blocks
      }
      specs {
        key
        value
      }
      certifications
      specSheet {
        file {
          url
          fileName
          contentType
        }
      }
      specAssets {
        gatsbyImageData(height: 300)
      }
      images {
        gatsbyImageData(width: 500)
      }
      features
      featuresAsset {
        gatsbyImageData
      }
      featureBlocks {
        ...Blocks
      }
      faqs {
        question
        answer {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;
