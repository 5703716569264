import React, { Component } from 'react'
import Default from './Default/index'
import SimpleBackButton from './SimpleBackButton/index'
import { graphql, StaticQuery } from 'gatsby'


class Header extends Component {

  displayHeader(data) {
    switch(this.props.display) {
      case 'Default': {
        return <Default {...this.props} navigation={data} />
      }
      case 'No Header': {
        return
      }
      case 'No Header with Back Button': {
        return <SimpleBackButton {...this.props} />
      }
      default: {
        return <Default {...this.props} payload={data} />
      }
    }
  }

  render() {
    return (
      <StaticQuery query={graphql`
        query ProductNavItemQuery {
          contentfulGlobals(name: {eq: "Default" }) {
            topLevelNavOrder
            categoryListNavOrder
            collectionListNavOrder
          }

          allContentfulNavItem {
            edges {
              node {
                topLevelName
                category
                shopifyCollectionName
              }
            }
          }

          allContentfulModal {
            edges {
              node {
                title
                description
                backgroundAsset {
                  gatsbyImageData
                }
              }
            }
          }

          allShopifyCollection {
            edges {
              node {
                title
                handle
                products {
                  
              
                  
                  title
                  handle
                  variants {
                    sku
                  }
                }
                image {
                  gatsbyImageData
                }
              }
            }
          }
        }`}
       render={data => {
          return this.displayHeader(data)
       }} />
    );
  }
}



export default Header;
